import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getAsset } from 'services/api/assets';
import {
  GatewayModuleType,
  GatewayUpdateLatestModulesVersionsModel,
  GatewayUpdateModel,
  GatewayUpdateProcessType,
} from 'core/domain/gateways/model';
import { GetLastGatewayModulesVersions } from 'core/domain/gateways/repositories/getLastGatewayModulesVersions';
import { useUserSession } from 'hooks/useUserSession';
import { useEnvironment } from 'hooks/useEnvironment';
import { GetGatewayDetail } from 'core/domain/gateways/repositories/getGatewayDetail';
import { GatewayDetailStatusModel, GatewayParsed } from 'core/domain/gateways/model/gateway/gatewayParsed';
import { GatewayStatusModel } from 'core/domain/gateways/model/gatewayStatus';
import { Thresholds } from 'core/domain/gateways/model/thresholds';
import { checkIfVersionIsOutdated } from 'utils/checkVersions';
import { UserPermissionType, UserRoleType } from 'models/users.model';
import { CheckGatewayModuleVersionUpdateState } from 'core/domain/gateways/repositories/checkGatewayModuleVersionUpdateState';
import { hasPermission } from 'services/permissions';
import { ActiveIssue } from 'core/domain/gateways/model/activeIssue';
import { CHECK_UPDATE_GATEWAY_MODULE_TIME_INTERVAL } from 'constants/time';
import { UpdateGatewayModuleVersion } from 'core/domain/gateways/repositories/updateGatewayModuleVersion';
import {
  GatewayNetworkInterfaceModel,
  GatewayNetworkStateStatusModel,
} from 'core/domain/gateways/model/gatewayStatus/gatewayStatusPayload';
import {
  CheckValuesManager,
  CheckValuesType,
  CustomGatewayDataModel,
  GatewayModuleCheckerModel,
  GatewayStatusCheckValueModel,
  GatewayStatusInformationTranslations,
  GatewayStatusKeys,
  GatewayStatusModuleConfig,
  GatewayStatusPercentValueInfoModel,
  GatewayStatusPercentValueModel,
  PercentValuesType,
} from './utils';
import { useAssignGateway } from './useAssignGateway';
import UserProfileContext, { UserProfileContextState } from 'context/UserProfileContext';

export const useAssetSharedGateway = () => {
  const { t } = useTranslation();
  const { assetId } = useParams<{ assetId: string }>();
  const { token } = useUserSession();
  const { host } = useEnvironment();
  const { userProfile } = useContext<UserProfileContextState>(UserProfileContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [gateways, setGateways] = useState<CustomGatewayDataModel[]>([]);
  const [updatingGateways, setUpdatingGateways] = useState<string[]>([]);
  const {
    unassignTranslations,
    onAssignGateway,
    onUnassignGateway,
    onConfirmUnassignGateway,
    onCancelConfirmationOnUnAssignGatewayModal,
    updateHasLinkedGateways,
    loadingGatewayAssignation,
    hasLinkedGateway,
    showConfirmationOnUnassignGatewayModal,
  } = useAssignGateway();

  const hasPermissionToEditGatewayLink =
    hasPermission(UserPermissionType.ASSIGN_GATEWAY) && hasPermission(UserPermissionType.UNASSIGN_GATEWAY);

  const isUserRoleSuperAdmin = userProfile?.roles.includes(UserRoleType.ROLE_ASSETS_MANAGER_SUPER_ADMIN);

  const lastAassVersion: string = !!gateways.length ? gateways[0].aassConfig.lastVersion : '';
  const lastSystemVersion: string = !!gateways.length ? gateways[0].systemConfig.lastVersion : '';

  const gatewayStatusInformationTranslations: GatewayStatusInformationTranslations = {
    title: t('information'),
    notReportedText: t('not_reported'),
    productionDateText: t('date_of_production'),
    networkStateText: t('_GATEWAY_STATUS_DETAIL_NETWORK_STATE'),
    networkStateStatusTranslations: {
      [GatewayNetworkStateStatusModel.GOOD]: t('_GATEWAY_STATUS_NETWORK_STATE_STATUS_GOOD'),
      [GatewayNetworkStateStatusModel.MEDIUM]: t('_GATEWAY_STATUS_NETWORK_STATE_STATUS_REGULAR'),
      [GatewayNetworkStateStatusModel.BAD]: t('_GATEWAY_STATUS_NETWORK_STATE_STATUS_BAD'),
      [GatewayNetworkStateStatusModel.UNKNOWN]: t('not_reported'),
    },
    networkStateLatencyText: t('_GATEWAY_STATUS_NETWORK_STATE_LATENCY'),
    networkStateBandWidthText: t('_GATEWAY_STATUS_NETWORK_STATE_BAND_WIDTH'),
    networkStateLostPackagesText: t('_GATEWAY_STATUS_NETWORK_STATE_LOST_PACKAGES'),
    networkInterfaceText: t('_GATEWAY_STATUS_DETAIL_CONNECTION_TYPE'),
    networkInterfaceDetailTranslations: {
      [GatewayNetworkInterfaceModel.ETHERNET]: t('_GATEWAY_STATUS_CONNECTION_TYPE_ETHERNET'),
      [GatewayNetworkInterfaceModel.WIFI]: t('_GATEWAY_STATUS_CONNECTION_TYPE_WIFI'),
      [GatewayNetworkInterfaceModel.LTE]: t('_GATEWAY_STATUS_CONNECTION_TYPE_LTE'),
    },
    lastConnectionText: t('last_connection'),
    statusText: t('status'),
    statusOfflineText: t('offline'),
    statusOnlineText: t('online'),
    healthText: t('health'),
    activeIssuesText: t('active_issues'),
    activeIssuesDetailTranslations: {
      [ActiveIssue.ASSISTANT_CLOUD_SERVICE]: t('ACTIVE_ISSUES.assistant_cloud_service'),
      [ActiveIssue.CPU_HDD_USED]: t('ACTIVE_ISSUES.cpu_hdd_used'),
      [ActiveIssue.CPU_HD_AVAILABLE]: t('ACTIVE_ISSUES.cpu_hd_available'),
      [ActiveIssue.CPU_HD_USED]: t('ACTIVE_ISSUES.cpu_hd_used'),
      [ActiveIssue.CPU_LOAD]: t('ACTIVE_ISSUES.cpu_load'),
      [ActiveIssue.CPU_RAM]: t('ACTIVE_ISSUES.cpu_ram'),
      [ActiveIssue.CPU_RAM_AVAILABLE]: t('ACTIVE_ISSUES.cpu_ram_available'),
      [ActiveIssue.CPU_RAM_USED]: t('ACTIVE_ISSUES.cpu_ram_used'),
      [ActiveIssue.CPU_TEMPERATURE]: t('ACTIVE_ISSUES.cpu_temperature'),
      [ActiveIssue.OHS_API]: t('ACTIVE_ISSUES.ohs_api'),
      [ActiveIssue.OHS_SERVICE]: t('ACTIVE_ISSUES.ohs_service'),
      [ActiveIssue.ONLINE]: t('ACTIVE_ISSUES.online'),
      [ActiveIssue.OPENHAB_API]: t('ACTIVE_ISSUES.openhab_api'),
      [ActiveIssue.OPENHAB_SERVICED]: t('ACTIVE_ISSUES.openhab_serviced'),
      [ActiveIssue.SYSTEM_TIME]: t('ACTIVE_ISSUES.system_time'),
      [ActiveIssue.TOTAL_INODES]: t('ACTIVE_ISSUES.total_inodes'),
      [ActiveIssue.TOTAL_SWAP]: t('ACTIVE_ISSUES.total_swap'),
      [ActiveIssue.UPTIME]: t('ACTIVE_ISSUES.uptime'),
      [ActiveIssue.USED_INODES]: t('ACTIVE_ISSUES.used_inodes'),
      [ActiveIssue.USED_SWAP]: t('ACTIVE_ISSUES.used_swap'),
      [ActiveIssue.VPN_CONNECTION]: t('ACTIVE_ISSUES.vpn_connection'),
      [ActiveIssue.VPN_IP]: t('ACTIVE_ISSUES.vpn_ip'),
      [ActiveIssue.ZWAVE_CONTROLLER]: t('ACTIVE_ISSUES.zwave_controller'),
    },
    gatewayVersionText: t('gw_version'),
    gatewayVersionUpdateButtonText: t('_GATEWAY_UPDATE_BUTTON_TEXT', { version: lastSystemVersion }),
    updateModuleErrorMessage: t('_GATEWAY_UPDATE_MODULE_VERSION_ERROR'),
    aassUpdateButtonText: t('_GATEWAY_UPDATE_BUTTON_TEXT', { version: lastAassVersion }),
    updateButtonInProgressText: t('updating'),
  };

  const getAssetLinkedGateways = async (): Promise<string[]> => {
    try {
      const { data } = await getAsset(assetId);
      return !!data ? data.gateways_qrs.map((gateway) => gateway.gateway_id) : [];
    } catch (error) {
      console.warn(error);
      return [];
    }
  };

  const getModulesVersion = async (): Promise<GatewayUpdateLatestModulesVersionsModel> => {
    try {
      return await GetLastGatewayModulesVersions({ host, token });
    } catch (error) {
      return { aass: '0.0.0', system: '0.0.0' };
    }
  };

  const checkValuesManager: CheckValuesManager = {
    [CheckValuesType.ZWAVE_CONTROLLER]: (element: boolean): GatewayStatusCheckValueModel => ({
      check: element,
      text: t(CheckValuesType.ZWAVE_CONTROLLER),
    }),
    [CheckValuesType.HELPER_API]: (element: boolean): GatewayStatusCheckValueModel => ({
      check: element,
      text: t(CheckValuesType.HELPER_API),
    }),
    [CheckValuesType.VPN_CONNECTION]: (element: boolean): GatewayStatusCheckValueModel => ({
      check: element,
      text: t(CheckValuesType.VPN_CONNECTION),
    }),
    [CheckValuesType.ASSISTANT_CLOUD_SERVICE]: (element: boolean): GatewayStatusCheckValueModel => ({
      check: element,
      text: t(CheckValuesType.ASSISTANT_CLOUD_SERVICE),
    }),
  };

  const getGatewaysStatusCheckedValues = (gateway: GatewayStatusModel) => {
    const gatewayKeys: GatewayStatusKeys[] = Object.keys(gateway) as GatewayStatusKeys[];
    const filteredGatewayKeys = gatewayKeys.filter((key) => Object.values(CheckValuesType).includes(key as CheckValuesType));
    const checkedValues: GatewayStatusCheckValueModel[] = [];
    filteredGatewayKeys.forEach((gatewayKey) => {
      if (typeof gateway[gatewayKey] === 'boolean') {
        const checkedFunction = checkValuesManager[gatewayKey as CheckValuesType];
        checkedValues.push(checkedFunction(gateway[gatewayKey] as boolean));
      }
    });
    return checkedValues;
  };

  const percentValuesManager = {
    [PercentValuesType.CPU_TEMPERATURE]: ({
      value,
      threshold,
      units,
    }: GatewayStatusPercentValueInfoModel): GatewayStatusPercentValueModel => ({
      value,
      threshold,
      unit: 'ºC',
      text: t(PercentValuesType.CPU_TEMPERATURE),
    }),
    [PercentValuesType.CPU_RAM_USED]: ({
      value,
      threshold,
      units,
    }: GatewayStatusPercentValueInfoModel): GatewayStatusPercentValueModel => ({
      value,
      threshold,
      unit: '%',
      text: t(PercentValuesType.CPU_RAM_USED),
    }),
    [PercentValuesType.CPU_HDD_USED]: ({
      value,
      threshold,
      units,
    }: GatewayStatusPercentValueInfoModel): GatewayStatusPercentValueModel => ({
      value,
      threshold,
      unit: '%',
      text: t(PercentValuesType.CPU_HDD_USED),
    }),
    [PercentValuesType.CPU_LOAD]: ({ value, threshold, units }: GatewayStatusPercentValueInfoModel): GatewayStatusPercentValueModel => ({
      value,
      threshold,
      unit: '',
      text: t(PercentValuesType.CPU_LOAD),
    }),
  };

  const getGatewaysStatusPercentValues = (gateway: GatewayDetailStatusModel) => {
    const gatewayKeys: GatewayStatusKeys[] = Object.keys(gateway) as GatewayStatusKeys[];
    const filteredGatewayKeys = gatewayKeys.filter((key) => Object.values(PercentValuesType).includes(key as PercentValuesType));
    const checkedValues: GatewayStatusPercentValueModel[] = [];
    filteredGatewayKeys.forEach((gatewayKey) => {
      if (typeof gateway[gatewayKey] === 'number') {
        const checkedFunction = percentValuesManager[gatewayKey as PercentValuesType];
        checkedValues.push(
          checkedFunction({
            value: gateway[gatewayKey] as number,
            threshold: gateway.thresholds[gatewayKey as keyof Thresholds] as number,
            units: gateway.units,
          })
        );
      }
    });
    return checkedValues;
  };

  const payloadToCustomGatewayStatusData = ({
    gatewayDetail,
    versions,
    updatingModule,
  }: {
    gatewayDetail: GatewayParsed;
    versions: GatewayUpdateLatestModulesVersionsModel;
    updatingModule?: GatewayModuleType;
  }): CustomGatewayDataModel => {
    const { aass, system } = versions;
    const { daemonVersion, gatewayVersion } = gatewayDetail;
    const checkedValues = !!gatewayDetail.status ? getGatewaysStatusCheckedValues(gatewayDetail.status) : [];
    const percentValues = !!gatewayDetail.status ? getGatewaysStatusPercentValues(gatewayDetail.status) : [];
    const isAssButtonEnabled = checkIfVersionIsOutdated({ minimumVersion: aass, version: daemonVersion });
    const isSystemButtonEnabled = checkIfVersionIsOutdated({ minimumVersion: system, version: gatewayVersion });
    const isAassVersionUpdating = !!updatingModule && updatingModule === GatewayModuleType.AASS;
    const isSystemVersionUpdating = !!updatingModule && updatingModule === GatewayModuleType.SYSTEM;

    return {
      ...gatewayDetail,
      assetId,
      checkedValues,
      percentValues,
      aassConfig: {
        isVersionUpdateError: false,
        isVersionUpdating: isAassVersionUpdating,
        isUpdateButtonDisabled: isSystemVersionUpdating,
        isUpdateButtonVisible: !!isUserRoleSuperAdmin && isAssButtonEnabled,
        lastVersion: aass,
      },
      systemConfig: {
        isVersionUpdateError: false,
        isVersionUpdating: isSystemVersionUpdating,
        isUpdateButtonDisabled: isAassVersionUpdating,
        isUpdateButtonVisible: !!isUserRoleSuperAdmin && isSystemButtonEnabled,
        lastVersion: system,
      },
    };
  };

  const getGatewaysCompletedStatus = async (linkedGateways: string[], versions: GatewayUpdateLatestModulesVersionsModel) => {
    return await Promise.all(
      linkedGateways.map(async (id): Promise<CustomGatewayDataModel> => {
        const gatewayDetail = await GetGatewayDetail(id);
        return payloadToCustomGatewayStatusData({ gatewayDetail, versions });
      })
    );
  };

  const checkGatewayModulesUpdateState = async (gateway: CustomGatewayDataModel): Promise<GatewayModuleCheckerModel[]> => {
    try {
      const modules = Object.values(GatewayModuleType) as GatewayModuleType[];
      const filteredModules: GatewayModuleCheckerModel[] = [];
      await Promise.all(
        modules.map(async (module) => {
          const response = await CheckGatewayModuleVersionUpdateState({ host, token, gatewayId: gateway.id, module });
          !!response && response.state !== GatewayUpdateProcessType.COMPLETED && filteredModules.push({ module, state: response.state });
        })
      );
      return filteredModules;
    } catch (error) {
      console.warn(error);
      return [];
    }
  };

  const updateFailedModuleConfig = (module: GatewayStatusModuleConfig): GatewayStatusModuleConfig => {
    const isGatewayModuleUpdatedToLastVersion = !module.isUpdateButtonVisible;
    const isError = !!isUserRoleSuperAdmin && !isGatewayModuleUpdatedToLastVersion;
    return { ...module, isVersionUpdateError: isError };
  };

  const moduleVersionUpdateInitialStateManager = {
    [GatewayUpdateProcessType.COMPLETED]: (module: GatewayStatusModuleConfig) => ({ ...module }),
    [GatewayUpdateProcessType.IN_PROGRESS]: (module: GatewayStatusModuleConfig) => ({ ...module, isVersionUpdating: true }),
    [GatewayUpdateProcessType.FAILED]: updateFailedModuleConfig,
  };

  const blockUpdateButtonIfOtherModuleIsUpdating = ({
    aassConfig,
    systemConfig,
  }: {
    aassConfig: GatewayStatusModuleConfig;
    systemConfig: GatewayStatusModuleConfig;
  }): { newAassConfig: GatewayStatusModuleConfig; newSystemConfig: GatewayStatusModuleConfig } => {
    const newAassConfig: GatewayStatusModuleConfig = { ...aassConfig, isUpdateButtonDisabled: systemConfig.isVersionUpdating };
    const newSystemConfig: GatewayStatusModuleConfig = { ...systemConfig, isUpdateButtonDisabled: aassConfig.isVersionUpdating };
    return { newAassConfig, newSystemConfig };
  };

  const checkGatewaysModulesUpdateState = async (gateways: CustomGatewayDataModel[]) => {
    try {
      const filteredUpdatingGateways: string[] = [];
      await Promise.all(
        gateways.map(async (gateway) => {
          const modules = await checkGatewayModulesUpdateState(gateway);
          if (!!modules.length) {
            let aassConfig: GatewayStatusModuleConfig = { ...gateway.aassConfig };
            let systemConfig: GatewayStatusModuleConfig = { ...gateway.systemConfig };
            modules.forEach(({ module, state }) => {
              state === GatewayUpdateProcessType.IN_PROGRESS && filteredUpdatingGateways.push(gateway.id);
              if (module === GatewayModuleType.AASS) {
                const aassModule: GatewayStatusModuleConfig = moduleVersionUpdateInitialStateManager[state](aassConfig);
                aassConfig = { ...aassModule };
              }
              if (module === GatewayModuleType.SYSTEM) {
                const systemModule: GatewayStatusModuleConfig = moduleVersionUpdateInitialStateManager[state](gateway.systemConfig);
                systemConfig = { ...systemModule };
              }
            });
            const { newAassConfig, newSystemConfig } = blockUpdateButtonIfOtherModuleIsUpdating({ aassConfig, systemConfig });
            const transformedGateways = gateways.map((currentGateway) => {
              return currentGateway.id === gateway.id ? { ...gateway, aassConfig: newAassConfig, systemConfig: newSystemConfig } : gateway;
            });
            setGateways(transformedGateways);
          }
        })
      );
      setUpdatingGateways(filteredUpdatingGateways);
    } catch (error) {
      console.warn(error);
    }
  };

  const getGatewaysListData = async () => {
    setLoading(true);
    try {
      const linkedGateways = await getAssetLinkedGateways();
      updateHasLinkedGateways(!!linkedGateways.length);
      if (!!linkedGateways.length) {
        const gatewayModulesLastVersions = await getModulesVersion();
        const gatewaysCompletedStatus = await getGatewaysCompletedStatus(linkedGateways, gatewayModulesLastVersions);
        setGateways(gatewaysCompletedStatus);
        checkGatewaysModulesUpdateState(gatewaysCompletedStatus);
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  };

  const updateAassModule = (gateway: CustomGatewayDataModel): CustomGatewayDataModel => {
    const aassConfig: GatewayStatusModuleConfig = { ...gateway.aassConfig, isVersionUpdating: true, isVersionUpdateError: false };
    const systemConfig: GatewayStatusModuleConfig = { ...gateway.systemConfig, isUpdateButtonDisabled: true };
    return { ...gateway, aassConfig, systemConfig };
  };

  const updateSystemModule = (gateway: CustomGatewayDataModel): CustomGatewayDataModel => {
    const aassConfig: GatewayStatusModuleConfig = { ...gateway.aassConfig, isUpdateButtonDisabled: true };
    const systemConfig: GatewayStatusModuleConfig = { ...gateway.systemConfig, isVersionUpdating: true, isVersionUpdateError: false };
    return { ...gateway, aassConfig, systemConfig };
  };

  const updateGatewayModuleManager = {
    [GatewayModuleType.AASS]: (gateway: CustomGatewayDataModel) => updateAassModule(gateway),
    [GatewayModuleType.SYSTEM]: (gateway: CustomGatewayDataModel) => updateSystemModule(gateway),
  };

  const transformGatewaysToShowUpdating = ({
    gateway,
    module,
  }: {
    gateway: CustomGatewayDataModel;
    module: GatewayModuleType;
  }): CustomGatewayDataModel[] => {
    return gateways.map((currentGateway) => {
      return currentGateway.id === gateway.id ? updateGatewayModuleManager[module](gateway) : gateway;
    });
  };

  const checkUpdateStateInProgress = ({ gateway, module }: GatewayUpdateModel) => {
    const newUpdatingGateways = updatingGateways.includes(gateway.id) ? updatingGateways : [...updatingGateways, gateway.id];
    setUpdatingGateways(newUpdatingGateways);
    const transformedGateways = transformGatewaysToShowUpdating({ gateway, module });
    setGateways(transformedGateways);
  };

  const updateGatewayAassModuleVersionStateError = (gateway: CustomGatewayDataModel): CustomGatewayDataModel => {
    const aassConfig: GatewayStatusModuleConfig = { ...gateway.aassConfig, isVersionUpdating: false, isVersionUpdateError: true };
    const systemConfig: GatewayStatusModuleConfig = { ...gateway.systemConfig, isUpdateButtonDisabled: false };
    return { ...gateway, aassConfig, systemConfig };
  };

  const updateGatewaySystemModuleVersionStateError = (gateway: CustomGatewayDataModel): CustomGatewayDataModel => {
    const aassConfig: GatewayStatusModuleConfig = { ...gateway.aassConfig, isUpdateButtonDisabled: false };
    const systemConfig: GatewayStatusModuleConfig = { ...gateway.systemConfig, isVersionUpdating: false, isVersionUpdateError: true };
    return { ...gateway, aassConfig, systemConfig };
  };

  const updateModuleVersionStateErrorManager = {
    [GatewayModuleType.AASS]: (gateway: CustomGatewayDataModel) => updateGatewayAassModuleVersionStateError(gateway),
    [GatewayModuleType.SYSTEM]: (gateway: CustomGatewayDataModel) => updateGatewaySystemModuleVersionStateError(gateway),
  };

  const transformGatewaysToShowUpdateError = ({ gateway, module }: GatewayUpdateModel): CustomGatewayDataModel[] => {
    return gateways.map((currentGateway) => {
      return gateway.id === currentGateway.id ? updateModuleVersionStateErrorManager[module](gateway) : gateway;
    });
  };

  const checkUpdateStateError = ({ gateway, module }: GatewayUpdateModel) => {
    const transformedGateways = transformGatewaysToShowUpdateError({ gateway, module });
    setGateways(transformedGateways);
    const newUpdatingGateways = updatingGateways.filter((id) => id !== gateway.id);
    setUpdatingGateways(newUpdatingGateways);
  };

  const moduleVersionUpdateStateManager = {
    [GatewayUpdateProcessType.COMPLETED]: getGatewaysListData,
    [GatewayUpdateProcessType.IN_PROGRESS]: checkUpdateStateInProgress,
    [GatewayUpdateProcessType.FAILED]: checkUpdateStateError,
  };

  const checkUpdateStatus = async ({ gateway, module }: GatewayUpdateModel) => {
    try {
      const response = await CheckGatewayModuleVersionUpdateState({ host, token, gatewayId: gateway.id, module });
      moduleVersionUpdateStateManager[response.state]({ gateway, module });
    } catch (error) {
      moduleVersionUpdateStateManager[GatewayUpdateProcessType.FAILED]({ gateway, module });
    }
  };

  const getGatewaysStatusUpdates = () => {
    gateways.forEach((gateway) => {
      gateway.aassConfig.isVersionUpdating && checkUpdateStatus({ gateway, module: GatewayModuleType.AASS });
      gateway.systemConfig.isVersionUpdating && checkUpdateStatus({ gateway, module: GatewayModuleType.SYSTEM });
    });
  };

  const onUpdateGatewayModule = async ({ gateway, module }: GatewayUpdateModel) => {
    try {
      checkUpdateStateInProgress({ gateway, module });
      await UpdateGatewayModuleVersion({ host, token, gatewayId: gateway.id, module });
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    getGatewaysListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (!!updatingGateways.length) {
      intervalId = setInterval(() => {
        getGatewaysStatusUpdates();
      }, CHECK_UPDATE_GATEWAY_MODULE_TIME_INTERVAL);
    }
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatingGateways]);

  const gatewaysOptions = {
    gateways,
    gatewayStatusInformationTranslations,
    onUpdateGatewayModule,
    hasPermissionToEditGatewayLink,
  };

  const gatewayActionsOptions = {
    unassignTranslations,
    onAssignGateway,
    onUnassignGateway,
    onConfirmUnassignGateway,
    onCancelConfirmationOnUnAssignGatewayModal,
    updateHasLinkedGateways,
    loadingGatewayAssignation,
    hasLinkedGateway,
    showConfirmationOnUnassignGatewayModal,
  };

  return {
    assetId,
    gatewaysOptions,
    gatewayActionsOptions,
    loading: loading || loadingGatewayAssignation,
  };
};
